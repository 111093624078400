import React, { useRef } from "react"
import "./Nav.scss"
import { Link, NavLink } from "react-router-dom"
import { useSpring, a } from "react-spring"
import styled from "styled-components"
import useTheme from "blocks/theme/useTheme"

const Styled = styled(a.div)`
  border-bottom: solid 2px ${props => props.theme.foreground};

  .nav-word-mark {
    a:hover {
      color: ${props => props.theme.accent}
    }
  }

  .nav-links span:hover {
    color: ${props => props.theme.accent}
  }

  .hamburger-menu {
    div {
      background-color: ${props => props.theme.foreground};
    }
  }
`

export const slugs = {
  about: 'about',
  actionSteps: 'action-steps',
  history: "history",
  values: "values",
  strategies: "strategies",
  resources: "resources"
}

const Nav = ({ hidden, route, setNavPanelHidden }) => {
  const onHomePage = [undefined, "home"].includes(route)
  const lastRouteRef = useRef(route)
  const theme = useTheme();
  const props = useSpring({
    from: {
      transform: `translateY(${!route ? "-100%" : "0"})`
    },
    transform: `translateY(${hidden ? "-100%" : "0"})`,
    reset: lastRouteRef.current !== route && onHomePage
  })

  if (lastRouteRef.current !== route) {
    lastRouteRef.current = route
  }

  function onLinkClick(navPanelHidden) {
    return () => {
      setNavPanelHidden(navPanelHidden)
    }
  }

  const activeNavLinkClass = "active-nav-link"

  return (
    <Styled className={`nav`} style={props} theme={theme}>
      <div className="nav-word-mark" onClick={onLinkClick(true)}>
        <Link to="/">MOTION FOR JUSTICE</Link>
      </div>
      <div className="nav-links-and-menu">
        <div className="nav-links" onClick={onLinkClick(true)}>
          <span>
            <NavLink activeClassName={activeNavLinkClass} to={"/" + slugs.history}>History</NavLink>
          </span>
          <span>
            <NavLink activeClassName={activeNavLinkClass} to={"/" + slugs.values}>Values</NavLink>
          </span>
          <span>
            <NavLink activeClassName={activeNavLinkClass} to={"/" + slugs.actionSteps}>Action Steps</NavLink>
          </span>
          <span>
            <NavLink activeClassName={activeNavLinkClass} to={"/" + slugs.strategies}>Strategies</NavLink>
          </span>
        </div>
        <div className="hamburger-menu" onClick={() => setNavPanelHidden(false)}>
          <div></div>
          <div></div>
        </div>
      </div>
    </Styled>
  )
}

export default Nav

