import { idtype } from "querypieces";

export default blocks => `
  ...on pageBuilder_theme_BlockType {
    ${idtype}
    theme
    children {
      ${blocks}
    }
  }
`;
