import React from "react";
import { Link as RouterLink } from "react-router-dom";

const Link = ({
  url,
  text,
  element,
  children,
  type,
  __typename,
  Component = RouterLink,
  ...rest
}) => {
  switch (type) {
    case "asset":
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {children || text}
        </a>
      );
    case "entry":
      return (
        <Component
          to={`/${element.uri === "__home__" ? "" : element.uri}`}
          {...rest}
        >
          {children || text}
        </Component>
      );

    default:
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" {...rest}>
          {children || text}
        </a>
      );
  }
};

export default Link;
