import { idtype, linkTo } from "querypieces"

export default `
    ... on pageBuilder_timeline_BlockType {
        ${idtype}
        heading
        richText
        eras {
            ... on eras_era_BlockType {
                eraTitle
                startYear
                endYear
            }
        }
        legalSystem {
            ... on legalSystem_systemicContext_BlockType {
                description
                year
                position
            }
        }
        themesOfProsecutionAndRacialInjustice {
            ... on themesOfProsecutionAndRacialInjustice_theme_BlockType {
                description
                year
                position
                ${linkTo}
            }
        }
    }
`