import React, { useRef, useState } from "react"
import Player from '@vimeo/player'
import "./full-width-video.scss"

const FullWidthVideo = ({vimeoLink}) => {
  const iframeRef = useRef(null)
  const [overlayClicked, setOverlayClicked] = useState(false)
  const vimeoPlayerLink = vimeoLink.replace(/vimeo\.com/, 'player.vimeo.com/video')

  function play() {
    if (!iframeRef.current) {
      return;
    }
    setOverlayClicked(true)
    const player = new Player(iframeRef.current)
    player.play()
  }

  return <div className="full-width-video">
    <div className="vimeo-embed-wrapper">
      <iframe
        ref={iframeRef}
        title="Motion for Justice"
        src={`${vimeoPlayerLink}?title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen>
      </iframe>
      {!overlayClicked && <div className="vimeo-embed-overlay" onClick={play}>
        <div className="vimeo-play-button">
          <span>PLAY VIDEO</span>
        </div>
      </div>}
    </div>
  </div>;
}

export default FullWidthVideo