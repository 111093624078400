import { idtype } from "querypieces"

export default `
  ...on pageBuilder_headingTextImage_BlockType {
    ${idtype}
    heading
    heading2
    richText
    richText2
    image {
      ... on images_Asset {
        optimizedSquare {
          placeholderBox
          srcset
        }
      }
    }
    imageAltText
    imageCaption
    captionPlacement
  }
`