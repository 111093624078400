import React from "react";
import "./footnotes.scss";
import useTheme from "blocks/theme/useTheme"
import styled from "styled-components"
import Accordion from "components/accordion"

const Styled = styled.div`
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.foreground};

  h2 {
    border-color: ${({theme}) => theme.foreground};
  }

  sub, sup {
    color: ${({theme}) => theme.accent};
  }

  span.definition {
    &::after {
      background-color: ${({theme}) => theme.foreground};
      color: ${({theme}) => theme.background};
    }

    span {
      background-color: ${({theme}) => theme.background};
      border-color: ${({theme}) => theme.foreground};
    }
  }
`

const Footnotes = ({
  heading,
  richText,
  heading2,
  richText2
}) => {
  const theme = useTheme();
  return <Styled
    className={"block footnotes"}
    theme={theme}>
    {heading ? <h2>{heading}</h2> : null}
    <div dangerouslySetInnerHTML={{ __html: richText }}></div>
    {heading2 && richText2 && <Accordion heading={heading2} richText={richText2} />}
  </Styled>;
}

export default Footnotes;