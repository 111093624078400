import React from "react";
import Blocks from "blocks";
import "./detailed-links.scss";

const DetailedLinks = ({ children }) => {
  return (
    <div className="detailed-links">
      <Blocks blocks={children} />
    </div>
  );
};

export default DetailedLinks;
