import React from "react"
import { Link } from "react-router-dom"
import { animated } from "react-spring"
import { useDrag } from "react-use-gesture"
import useDimensions from "react-use-dimensions"
import { useInertia } from "./inertia"
import "./timeline.scss"
import { ReactComponent as Arrow } from "./../../images/arrow.svg"

const Timeline = ({
    heading, richText, eras, legalSystem, themesOfProsecutionAndRacialInjustice }) => {
    const screenAbove700pxWide = window.matchMedia("screen and (min-width: 700px)")
    const [{ x }, set] = useInertia({ x: 0 })
    const [ref, { width }] = useDimensions()

    const bind = useDrag(({ down, movement: [mx], vxvy: [vx] }) => {
            if (down) {
                set({ x: mx, config: { decay: false, velocity: 0 } })
            } else {
                set({
                    x: mx,
                    config: {
                        inertia: true,
                        bounds: { x: [-width + (width / 10), 0] },
                        velocities: { x: vx }
                    }
                })
            }
        },
        {
            delay: true,
            initial: () => [x.get(), 0],
            bounds: { left: -width + (width / 10), right: 0 },
            rubberband: true
        }
    )

    const ticks = [1, 21, 41, 61, 81, 101, 121, 141, 161].map(column =>
        (<div key={column} className="tick" style={{ gridColumn: column }} data-year={1860 + column - 1}></div>))
    const eraDivs = eras.map(e =>
        (<div
            className="era"
            key={e.eraTitle}
            style={{ gridColumn: `${e.startYear - 1859} / span ${e.endYear - e.startYear}`}}>
            <div>{e.eraTitle}</div>
        </div>))
    const legalSystemDivs = legalSystem.map(sc =>
        (<div
            className="systemic-context"
            key={sc.description}
            style={{
                gridColumn: `${sc.year - 1859} / span 15`,
                gridRow: sc.position === "low" ? 2 : 1
            }}>
            <div>{sc.description}</div>
        </div>))
    const themeDivs = themesOfProsecutionAndRacialInjustice.map(({ description, linkTo, position, year }) => {
        const entryURI = linkTo && linkTo.element && linkTo.element.uri;
        return (
            <div
                className="theme timeline-card"
                key={description}
                style={{
                    gridColumn: `${year - 1859} / span 25`,
                    gridRow: position === "low" ? 2 : 1
                }}>
                <Link to={"/" + entryURI}>
                    <div>{description}</div>
                    <span>{linkTo.text}</span>
                </Link>
            </div>
        )
    })
    return (
        <div className="timeline-wrapper">
            <div className="block">
                <h2>{heading}</h2>
                <div className="description" dangerouslySetInnerHTML={{ __html: richText }}></div>
            </div>
            <div className="arrows">
                <animated.div
                    style={{
                        opacity: x.interpolate(x => x > -1 ? 0.25 : 1)
                    }}
                    className="left-arrow"
                    onClick={() => {
                        set({
                            x: Math.min(x.get() + width / 4.5, 0),
                            immediate: true
                        })
                    }}><Arrow /></animated.div>
                <animated.div
                    className="right-arrow"
                    style={{
                        opacity: x.interpolate(x => x < -width + (width / 10 + 1) ? 0.25 : 1)
                    }}
                    onClick={() => {
                        set({
                            x: Math.max(x.get() - width / 4.5, -width + (width / 10)),
                            immediate: true
                        })
                    }}><Arrow /></animated.div>
            </div>
            <animated.div
                ref={!screenAbove700pxWide.matches ? ref : null}
                {...(!screenAbove700pxWide.matches ? bind() : {})}
                style={!screenAbove700pxWide.matches ? { x, touchAction: "pan-y" } : {}}
                className="timeline">
                <div className="row-headers">
                    <div></div>
                    <div>ERAS</div>
                    <div>LEGAL SYSTEM</div>
                    <div>
                        <div className="timeline-card">
                            <div>THEMES OF PROSECUTION AND RACIAL INJUSTICE</div>
                        </div>
                    </div>
                </div>
                <div className="timeline-sections-wrapper">
                    <animated.div
                        ref={screenAbove700pxWide.matches ? ref : null}
                        {...(screenAbove700pxWide.matches ? bind() : {})}
                        style={screenAbove700pxWide.matches ? { x, touchAction: "pan-y" } : {}}
                        className="timeline-sections">
                        <div></div>
                        <div className="eras">{eraDivs}</div>
                        <div className="legal-system">{legalSystemDivs}</div>
                        <div className="themes">{themeDivs}</div>
                        {ticks}
                    </animated.div>
                </div>
            </animated.div>
        </div>
    )
}

export default Timeline