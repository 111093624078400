import { idtype, buttonLink } from 'querypieces'

export default `
  ...on pageBuilder_fullWidthText_BlockType {
    ${idtype}
    toggle
    heading
    richText
    ${buttonLink}
  }
`