import React from "react"

import actionSteps from "./action-steps"
import code from "./code"
import detailedLink from "./detailed-links/detailed-link"
import detailedLinks from "./detailed-links"
import fileDownload from "./file-download"
import footnotes from "./footnotes"
import fullWidthText from "./full-width-text"
import fullWidthVideo from "./full-width-video"
import iconHeadingTextLink from "./icon-heading-text-link"
import imageLink from "./image-links/image-link"
import imageLinks from "./image-links"
import headingText from "./heading-text"
import headingTextImage from "./heading-text-image"
import headingTextImageLink from "./heading-text-image-link"
import pullQuote from "./pull-quote"
import resource from "./resources/resource"
import resourceEntries from "./resources/resource-entries"
import resources from "./resources"
import strategies from "./strategies"
import spacer from "./spacer"
import theme from "./theme"
import timeline from "./timeline"
import videoHeaderDetail from "./video-header-detail"

const components = {
  actionSteps,
  code,
  detailedLink,
  detailedLinks,
  fileDownload,
  footnotes,
  fullWidthText,
  fullWidthVideo,
  iconHeadingTextLink,
  imageLink,
  imageLinks,
  headingText,
  headingTextImage,
  headingTextImageLink,
  pullQuote,
  resource,
  resourceEntries,
  resources,
  spacer,
  strategies,
  theme,
  timeline,
  videoHeaderDetail
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map(block => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} />;
  });
};
export default Blocks;
