import { idtype, iconHeadingTextLink } from "querypieces";

export default `
...on pageBuilder_actionSteps_BlockType {
    ${idtype}
    children {
      ${iconHeadingTextLink}
    }
  }
`;

