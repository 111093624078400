import { gql } from "apollo-boost"
import { idtype } from "querypieces"
import { useQuery } from "@apollo/react-hooks"

const query = gql`
  query {
    footer: globalSet (handle: "footerLinks") {
      ...on footerLinks_GlobalSet {
        socialLinks {
          ...on socialLinks_item_BlockType {
            ${idtype}
            icon {
              ...on images_Asset {
                url
                title
              }
            }
            linkTo
          }
        }
      }
    }
  }
`

const useFooter = () => {
  return useQuery(query)
}

export default useFooter