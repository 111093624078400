/* eslint no-eval: "off" */
import React, { useLayoutEffect, useState } from "react"

const Code = ({code}) => {
    const [codeSansScript, setCodeSansScript] = useState("")
    useLayoutEffect(() => {
        let extractscript=/<script>(.+)<\/script>/gi.exec(code)
        setCodeSansScript(code.replace(extractscript[0],""))
        window.eval(extractscript[1])
    }, [code])
    return <div className="block" dangerouslySetInnerHTML={{ __html: codeSansScript }}></div>
}

export default Code