import React from "react"
import { useSpring, a } from "react-spring"
import styled from "styled-components"
import "./full-width-text.scss";
import useTheme from "blocks/theme/useTheme"

const Styled = styled.div`
  &.block.full-width-text {
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.foreground};

    .button-link {
      color: ${({theme}) => theme.accent};

      &::before {
        border-color: ${({theme}) => theme.accent};
      }

      .button-link-after {
        border-color: ${({theme}) => theme.accent};
      }
    }

    sub, sup {
      color: ${({theme}) => theme.accent};
    }

    span.definition {
      &::after {
        background-color: ${({theme}) => theme.foreground};
        color: ${({theme}) => theme.background};
      }

      span {
        background-color: ${({theme}) => theme.background};
        border-color: ${({theme}) => theme.foreground};
      }
    }
  }
`

const FullWidthText = ({
  buttonLink,
  heading,
  richText,
  toggle: alternateHeadingFont
}) => {
  const theme = useTheme();
  const [animatedProps, setAnimatedProps] = useSpring(() =>
    ({
      borderRight: 'solid 0px transparent',
      width: '0%',
      config: {
        duration: 300
      }
    }))

  function onMouseEnter(e) {
    setAnimatedProps({
      to: [
        {width: '66.67%', borderRight: 'solid 2px transparent'},
        {borderRight: 'solid 2px ' + theme.accent}
      ]
    })
  }

  function onMouseLeave(e) {
    setAnimatedProps({
      to: [
        {borderRight: 'solid 3px transparent'},
        {width: '0%'},
        {borderRight: 'solid 0px transparent'},
      ]
    })
  }

  return <Styled
    className={"block full-width-text" + (alternateHeadingFont ? " alternate-heading-font" : "")}
    theme={theme}>
    {heading ?
    (alternateHeadingFont ?
    <h1>{heading}</h1> :
    <h2>{heading}</h2>) : null}
    <div dangerouslySetInnerHTML={{ __html: richText }}></div>
    {buttonLink.text && buttonLink.url ?
    <a href={buttonLink.url}
      className="button-link"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {buttonLink.text}
      <a.div className="button-link-after" style={animatedProps}></a.div>
    </a> : null}
  </Styled>;
}

export default FullWidthText;