import { idtype, iconHeadingTextLink } from "querypieces"

export default `
... on pageBuilder_strategies_BlockType {
    ${idtype}
    children {
      ${iconHeadingTextLink}
    }
  }
`
