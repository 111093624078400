import React from "react"
import Image from "components/image"

const DetailedLink = ({ image, imageAltText, heading, plainText, linkTo }) => {
  return (
    <div className="detailed-link">
      <a href={linkTo.url}>
        {image.length ?
        <Image alt={imageAltText} {...image[0].optimized169} /> : null}
        <div className="detailed-link-details">
          <h1>{heading}</h1>
          <div>{plainText}</div>
          <span>{linkTo.text}</span>
        </div>
      </a>
    </div>
  );
};

export default DetailedLink;
