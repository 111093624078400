import React from "react"
import "./image.scss"
import styled from "styled-components"
import useTheme from "blocks/theme/useTheme"

const Styled = styled.div`
  span {
    align-self: ${({captionPlacement}) => captionPlacement === "left" ? "flex-start" : "flex-end"};
    color: ${({theme}) => theme.accent};
  }
`

const Image = ({
  alt = "",
  className = "",
  placeholderBox,
  srcset,
  caption,
  captionPlacement,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Styled className="image-wrapper" captionPlacement={captionPlacement} theme={theme}>
      <img
        alt={alt}
        src={placeholderBox}
        srcSet={srcset}
        className={`image ${className}`}
        {...rest}
      />
      {caption && <span>{caption}</span>}
    </Styled>
  )
}

export default Image;
