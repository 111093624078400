import React, { useState } from "react"
import { animated, useSpring } from "react-spring"
import useDimensions from "react-use-dimensions"
import "./Accordion.scss"

const Accordion = ({heading, richText}) => {
  const [contentRef, { height }] = useDimensions()
  const [open, setOpen] = useState(false)
  const springProps = useSpring({
    height: open ? height : 0
  })

  return (
    <div className="accordion">
      <div className="accordion-heading" onClick={() => setOpen((prev) => !prev)}>
        <h3>{heading}</h3>
        <animated.div
          class="accordion-heading-caret"
          style={{
            transform: springProps.height
              .interpolate({range: [0, height], output: [90, 0]})
              .interpolate(d => `rotateZ(${d}deg)`)
          }}>&gt;</animated.div>
      </div>
      <animated.div className="accordion-content-wrapper" style={springProps}>
        <div ref={contentRef} dangerouslySetInnerHTML={{ __html: richText }}></div>
      </animated.div>
    </div>
  )
}

export default Accordion

