import React from "react";
import { Route, Switch } from "react-router-dom";
import Page from "components/page";

const App = () => {
  return (
    <Switch>
      <Route path={"/:slug/:nestedSlug?"} component={Page} />
      <Route path={"/:slug?"} component={Page} />
    </Switch>
  );
};

export default App;

