import { idtype, linkTo } from "querypieces";

export default `
...on pageBuilder_detailedLinks_BlockType {
    ${idtype}
    children {
      ... on pageBuilder_detailedLink_BlockType {
        ${idtype}
        heading
        plainText
        image {
          ... on images_Asset {
            optimized169 {
              placeholderBox
              srcset
            }
          }
        }
        imageAltText
        ${linkTo}
      }
    }
  }
`;
