import React from "react"
import SVG from 'react-inlinesvg'
import "./Footer.scss"
import useFooter from "./useFooter"
import { ReactComponent as VeraWordMark} from "./../../images/Vera_Hor_Black.svg"
import IipLogo from "./../../images/iip_full.png"
import NewsletterSignUp from "components/newsletter-sign-up"

const Footer = () => {
  const { data } = useFooter()

  return (
    <div className="Footer">
      <div className="Footer-mark-wrapper">
        <div className="Footer-word-mark">
          <VeraWordMark />
          <img alt="IIP" src={IipLogo} />
        </div>
        <NewsletterSignUp />
      </div>
      <div>
        {data && <SocialLinks items={data.footer.socialLinks} />}
        <div className="Footer-trade-mark">©2021 Vera Institute of Justice, Inc.</div>
      </div>
    </div>
  )
}

export const SocialLinks = ({ items }) => {
  return (
    <div className="Footer-social-links">
      {items.map(({ id, icon, linkTo }) => {
        return (
          <div key={id}>
            <a href={linkTo} target="_blank" rel="noopener noreferrer">
              <SVG src={icon[0].url} />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default Footer;
