import React from "react"
import "./heading-text-image-link.scss"
import Image from "components/image"

const HeadingTextImageLink = ({ image, imageAltText, imageCaption, captionPlacement, heading, richText, linkTo }) => {
  const screenAbove1000pxWide = window.matchMedia('screen and (min-width: 1000px)')
  const imageForScreenSize = image.length ? (
    screenAbove1000pxWide.matches ?  image[0].optimizedSquare : image[0].optimized169
  ) : null

  return (
    <div className="heading-text-image-link">
      {imageForScreenSize ?
      <Image
        alt={imageAltText}
        caption={imageCaption}
        captionPlacement={captionPlacement}
        {...imageForScreenSize} /> :
      null}
      <div className="block heading-text-image-link-block">
        <h1>{heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: richText }}></div>
        {linkTo.url && linkTo.text ? 
        <a href={linkTo.url}>{linkTo.text}</a> :
        null}
      </div>
    </div>
  )
}

export default HeadingTextImageLink