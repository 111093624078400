import { idtype, linkTo } from 'querypieces';

export default `
  ...on pageBuilder_headingTextImageLink_BlockType {
    ${idtype}
    heading
    richText
    image {
      ... on images_Asset {
        optimized169 {
          placeholderBox
          srcset
        }
        optimizedSquare {
          placeholderBox
          srcset
        }
      }
    }
    imageAltText
    imageCaption
    captionPlacement
    ${linkTo}
  }
`