import { idtype } from "querypieces"

export default `
  ... on pageBuilder_footnotes_BlockType {
    ${idtype}
    heading
    richText
    heading2
    richText2
  }
`
