import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import actionSteps from "blocks/action-steps/query"
import code from "blocks/code/query"
import detailedLinks from "blocks/detailed-links/query"
import fileDownload from "blocks/file-download/query"
import footnotes from "blocks/footnotes/query"
import fullWidthText from "blocks/full-width-text/query"
import fullWidthVideo from "blocks/full-width-video/query"
import iconHeadingTextLink from "blocks/icon-heading-text-link/query"
import imageLinks from "blocks/image-links/query"
import headingText from "blocks/heading-text/query"
import headingTextImage from "blocks/heading-text-image/query"
import headingTextImageLink from "blocks/heading-text-image-link/query"
import pullQuote from "blocks/pull-quote/query"
import resources, { resourceEntriesBlock } from "blocks/resources/query"
import spacer from "blocks/spacer/query"
import strategies from "blocks/strategies/query"
import theme from "blocks/theme/query"
import timeline from "blocks/timeline/query"
import videoHeaderDetail from "blocks/video-header-detail/query"

const blocks = `
  ${actionSteps}
  ${code}
  ${detailedLinks}
  ${fileDownload}
  ${footnotes}
  ${fullWidthText}
  ${fullWidthVideo}
  ${iconHeadingTextLink}
  ${imageLinks}
  ${headingText}
  ${headingTextImage}
  ${headingTextImageLink}
  ${pullQuote}
  ${resources}
  ${resourceEntriesBlock}
  ${spacer}
  ${strategies}
  ${timeline}
  ${videoHeaderDetail}
`;

const query = gql`
  query($slug: [String]) {
    entry(slug: $slug) {
      ... on pages_page_Entry {
        typeHandle
        theme
        pageBuilder {
          ${blocks}
          ${theme(blocks)}
        }
        children {
          typeHandle
          ... on pages_panelPage_Entry {
            id
            slug
            title
            image {
              url
            }
            heading
            theme
            plainText
            pageBuilder {
              ${blocks}
              ${theme(blocks)}
            }
          }
        }
      }
    }
  }
`;

const usePage = slug => {
  if (!slug) {
    slug = "home";
  }
  return useQuery(query, {
    variables: {
      slug
    },
    notifyOnNetworkStatusChange: true
  });
};

export default usePage;
