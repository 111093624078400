import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"

const query = gql`
  query {
    newsletterSignUp: globalSet (handle: "newsletterSignUp") {
      ...on newsletterSignUp_GlobalSet {
        mailchimpSubscribeUrl
      }
    }
  }
`

const useNewsletterSignUp = () => {
  return useQuery(query)
}

export default useNewsletterSignUp