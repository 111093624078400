import { idtype } from "querypieces"

export default `
  ...on pageBuilder_fileDownload_BlockType {
    ${idtype}
    heading
    plainText
    downloadableFile {
      url
    }
  }
`