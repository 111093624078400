import React from "react"
import "./heading-text-image.scss"
import Image from "components/image"
import Accordion from "components/accordion"

const HeadingTextImage = ({ heading, heading2, image, imageAltText, imageCaption, captionPlacement, richText, richText2 }) => {
  return (
    <div className="heading-text-image">
      {image.length ?
      <Image
        alt={imageAltText}
        caption={imageCaption}
        captionPlacement={captionPlacement}
        {...image[0].optimizedSquare} /> :
      null}
      <div className="block heading-text-image-block">
        <h2>{heading}</h2>
        <div dangerouslySetInnerHTML={{ __html: richText }}></div>
        {heading2 && richText2 && <Accordion heading={heading2} richText={richText2} />}
      </div>
    </div>
  )
}

export default HeadingTextImage