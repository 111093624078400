import React, { useMemo, useRef } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom"
import "./PagePanel.scss";
import { useSpring, a } from 'react-spring';
import styled from "styled-components"
import { PageBuilder } from "components/page"
import { ThemeContext, themes } from "blocks/theme"
import { hexToRGB } from "utilities"

const Styled = styled(a.div)`
  .page-panel {
    background-color: ${({theme}) => theme.background};
    color: ${({theme}) => theme.foreground};

    .close-button {
      background: inherit;
      border-bottom: solid 2px ${({theme}) => theme.foreground};

      div {
        background-color: ${({theme}) => theme.foreground}; 
      }
    }

    .page-panel-heading {
      .image {
        background-image: url(${props => props.image.length ? props.image[0].url : ''});
      }
    }

    .page-panel-prev-next {
      .previous:hover, .next:hover {
        span {
          color: ${({theme}) => theme.accent};;
        }
      }
    }

    sub, sup {
      color: ${({theme}) => theme.accent};
    }

    span.definition {
      &::after {
        background-color: ${({theme}) => theme.foreground};
        color: ${({theme}) => theme.background};
      }

      span {
        background-color: ${({theme}) => theme.background};
        border-color: ${({theme}) => theme.foreground};
      }
    }
  }

  @media screen and (min-width: 1000px) {
    .page-panel {
      .close-button {
        background: none;
        border-bottom: none;
      }
    }
  }
`

const PagePanel = ({entries, selectedEntryIndex, hidden}) => {
  const entry = entries[selectedEntryIndex]
  const prevEntry = entries[(selectedEntryIndex - 1 + entries.length) % entries.length]
  const nextEntry = entries[(selectedEntryIndex + 1) % entries.length]
  const match = useRouteMatch()
  const history = useHistory()
  const theme = useMemo(() => entry ? themes[entry.theme] : themes['darkGreen'], [entry])
  const props = useSpring({
    from: {
      backgroundColor: hexToRGB(theme.foreground, 0),
      transform: `translateX(-100%)`
    },
    to: async (next) => {
      await next({ transform: `translateX(${hidden ? '-100' : '0'}%)` })
      await next({ backgroundColor: hexToRGB(theme.foreground, hidden ? 0 : 0.7) })
    },
    onRest() {
      if (hidden && pagePanelRef.current) {
        pagePanelRef.current.scrollTop = 0;
      }
    }
  })
  const pagePanelRef = useRef(null)

  function onClose() {
    history.push("/" + match.params.slug)
  }

  function scrollToTop() {
    if (pagePanelRef.current) {
      pagePanelRef.current.scrollTop = 0;
    }
  }

  return (
    <Styled
      className="page-panel-overlay"
      image={entry ? entry.image : []}
      onClick={onClose}
      style={props}
      theme={theme}>
      <div
        ref={pagePanelRef}
        className="page-panel"
        onClick={e => e.stopPropagation()}>
        {entry ?
        <ThemeContext.Provider value={theme}>
          <div className="close-button" onClick={onClose}>
            <div></div>
            <div></div>
          </div>
          <div className="progress-detail">{entry.plainText}</div>
          <div className="page-panel-heading">
            <h1>{entry.title}</h1>
            {entry.image.length ?
            <div className="image" /> :
            null}
          </div>
          {entry.heading &&
          <div className="page-panel-sub-heading">
            <h2>{entry.heading}</h2>
          </div>}
          <PageBuilder entry={entry} />
          <div className="page-panel-prev-next">
            <Link to={`/${match.params.slug}/${prevEntry.slug}`} className="previous" onClick={() => scrollToTop()}>
              <h2>{prevEntry.title}</h2>
              <span>PREVIOUS</span>
            </Link>
            <Link to={`/${match.params.slug}/${nextEntry.slug}`} className="next"  onClick={() => scrollToTop()}>
              <h2>{nextEntry.title}</h2>
              <span>NEXT</span>
            </Link>
          </div>
        </ThemeContext.Provider> :
        null}
      </div>
    </Styled>
  );
}

export default PagePanel;
