import React from "react"
import "./Banner.scss"
import useBanner from "./useBanner"

const Banner = () => {
  const { data } = useBanner()
  return (
    data ? <div className="banner" dangerouslySetInnerHTML={{ __html: data.banner.richText }}></div> : null
  )
}

export default Banner

