import { idtype, linkTo } from "querypieces";

export default `
...on pageBuilder_imageLinks_BlockType {
    ${idtype}
    children {
      ...on pageBuilder_imageLink_BlockType {
        ${idtype}
        image {
          ... on images_Asset {
            optimized85 {
              placeholderBox
              srcset
            }
          }
        }
        imageAltText
        ${linkTo}
      }
    }
  }
`;