import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import { resourceEntries } from "./query"

const query = gql`${resourceEntries}`

const useResourceEntries = () => {
  return useQuery(query)
}

export default useResourceEntries