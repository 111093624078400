import React, { useState } from "react";
import "./resources.scss";
import styled from "styled-components"
import Blocks from "blocks";
import { hexToRGB } from "utilities"
import useTheme from "blocks/theme/useTheme"
import useCategories from "components/categories/useCategories"
import useResourceEntries from "./useResourceEntries";

const Styled = styled.div`
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.foreground};

  .resource-filters {
    .category-filter-buttons {
      div {
        border-color: ${({theme}) => hexToRGB(theme.accent, 1)};
        color: ${({theme}) => theme.accent};

        &.active {
          background-color: ${({theme}) => theme.accent};
          color: ${({theme}) => theme.accentInverse};
        }
      }
    }
  }

  .resources {
    .resource {
      border-color: ${({theme}) => theme.foreground};
    }
  }

  sub, sup {
    color: ${({theme}) => theme.accent};
  }


  span.definition {
    &::after {
      background-color: ${({theme}) => theme.foreground};
      color: ${({theme}) => theme.background};
    }

    span {
      background-color: ${({theme}) => theme.background};
      border-color: ${({theme}) => theme.foreground};
    }
  }

  @media screen and (min-width: 1000px) {
    .resource-filters {
      .category-filter-buttons {
        div:hover {
          border-color: ${({theme}) => hexToRGB(theme.accent, 0)};
        }
      }
    }

    .resources {
      .resource:hover {
        background-color: ${({theme}) => theme.foreground};
        color: ${({theme}) => theme.background};

        a {
          span {
            color: ${({theme}) => theme.backgroundInverse}
          }
        }
      }
    }
  }
`

const ResourceEntries = () => {
  const theme = useTheme()
  const { data } = useCategories()
  const { data: resourceEntriesData } = useResourceEntries()
  const [typeFilters, setTypeFilters] = useState(() => [])
  const [themeFilters, setThemeFilters] = useState(() => [])

  return (
    <Styled className="resources-wrapper" theme={theme}>
      {data && <div className="resource-filters">
        <div>
          <h3>RESOURCE TYPE</h3>
          <CategoryFilterButtonsForCategoryGroup
            activeFilters={typeFilters}
            setActiveFilters={setTypeFilters}
            categories={data.categories}
            categoryGroup="resourceTypes" />
        </div>
        <div>
          <h3>RESOURCE THEME</h3>
          <CategoryFilterButtonsForCategoryGroup
            activeFilters={themeFilters}
            setActiveFilters={setThemeFilters}
            categories={data.categories}
            categoryGroup="resourceThemes" />
        </div>
      </div>}
      {resourceEntriesData && <div className="resources">
        <Blocks blocks={filterResources(resourceEntriesData.resourceEntries, typeFilters, themeFilters)} />
      </div>}
    </Styled>
  )
}

const CategoryFilterButtonsForCategoryGroup = ({categories, categoryGroup, activeFilters, setActiveFilters}) => {
  const onClick = (category) => () => {
    activeFilters.includes(category.title) ?
      setActiveFilters(activeFilters.filter(f => f !== category.title)) :
      setActiveFilters([...activeFilters, category.title])
  }

  return (
    <div className="category-filter-buttons">
      {categories.filter(c => c.groupHandle === categoryGroup).map(c => (
        <div key={c.title} className={activeFilters.includes(c.title) ? "active" : ""} onClick={onClick(c)}>
          {c.title}
        </div>
      ))}
    </div>
  )
}

const filterResources = (resources, typeFilters, themeFilters) => {
  return resources
    .filter(r => typeFilters.length === 0 ||
      r.resourceTypes.filter(({title}) => typeFilters.includes(title)).length > 0)
    .filter(r => themeFilters.length === 0 ||
      r.resourceThemes.filter(({title}) => themeFilters.includes(title)).length > 0)
}

export default ResourceEntries;
