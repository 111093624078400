import { idtype, linkTo } from "querypieces";

const resource = (title) => `
  ${idtype}
  ${title}
  richTextHeading
  richText
  plainText
  ${linkTo}
  resourceTypes {
    title
  }
  resourceThemes {
    title
  }
`
export const resourceEntriesBlock = `
  ...on pageBuilder_resourceEntries_BlockType {
    ${idtype}
  }
`

export const resourceEntries = `
  query {
    resourceEntries: entries (section: "resources") {
      ... on resources_resource_Entry {
        ${resource("heading: title")}
      }
    }
  }
`

export default `
...on pageBuilder_resources_BlockType {
  ${idtype}
  heading
  children {
    ...on pageBuilder_resource_BlockType {
      ${resource("heading")}
    }
  }
}
`;
