import React from "react";
import styled from "styled-components"
import "./action-steps.scss";
import Blocks from "blocks";
import useTheme from "blocks/theme/useTheme"

const Styled = styled.div`
  .icon-heading-text-link {
    border-top: solid 2px ${({theme}) => theme.foreground};
  }

  sub, sup {
    color: ${({theme}) => theme.accent};
  }

  span.definition {
    &::after {
      background-color: ${({theme}) => theme.foreground};
      color: ${({theme}) => theme.background};
    }

    span {
      background-color: ${({theme}) => theme.background};
      border-color: ${({theme}) => theme.foreground};
    }
  }

  @media screen and (min-width: 700px) {
    border-top: solid 2px ${({theme}) => theme.foreground};


    .icon-heading-text-link {
      border-bottom: solid 2px ${({theme}) => theme.foreground};
      border-right: solid 2px ${({theme}) => theme.foreground};
      border-top: none;
      transition: all 0.3s;

      &:hover {
        background-color: ${({theme}) => theme.backgroundInverse};
        color: ${({theme}) => theme.foregroundInverse};
      }
    }
  }
`;

const ActionSteps = ({ children, setNavPanelHidden }) => {
  const theme = useTheme()
  const childrenWithHandlers = children.map(c => ({
    ...c,
    setNavPanelHidden
  }))
  return (
    <Styled className="action-steps" theme={theme}>
      <Blocks blocks={childrenWithHandlers} />
    </Styled>
  )
}

export default ActionSteps;
