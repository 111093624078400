import React from "react";
import "./NavPanel.scss";
import { NavLink } from "react-router-dom";
import Link from "components/link";
import { useSpring, a } from "react-spring";
import useFooter from "components/footer/useFooter";
import { SocialLinks } from "components/footer";
//import { slugs } from "components/nav";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { linkTo } from "querypieces";

const query = gql`
  query {
    globalSet(handle:"nav") {
      ...on nav_GlobalSet {
        navBuilder {
          ...on navBuilder_linkGroup_BlockType {
            id
            children {
              ...on navBuilder_link_BlockType {
                id
                ${linkTo}
              }
            }
          }
        }
      }
    }
  }
`;
const NavPanel = ({ hidden, route, setHidden }) => {
  const { loading: navLoading, error: navError, data: navData } = useQuery(
    query
  );

  console.log(navLoading, navError, navData);

  const props = useSpring({
    from: {
      transform: `translateX(100%)`,
    },
    to: async (next) => {
      await next({ transform: `translateX(${hidden ? "100" : "0"}%)` });
      await next({
        backgroundColor: `rgba(255, 255, 255, ${hidden ? "0.0" : "0.7"})`,
      });
    },
  });
  const { data } = useFooter();

  function close() {
    setHidden(true);
  }

  const activeNavPanelLinkClass = "active-nav-panel-link";

  return (
    <a.div className="nav-panel-overlay" style={props} onClick={close}>
      <div className="nav-panel" onClick={(e) => e.stopPropagation()}>
        <div className="close-button" onClick={close}>
          <div></div>
          <div></div>
        </div>
        {navData &&
          navData.globalSet.navBuilder.map(({ id, children }, i) => {
            return (
              <React.Fragment key={id}>
                {i > 0 && <div className="nav-panel-divider"></div>}
                <div className="nav-panel-links" onClick={close}>
                  {children.map(({ id, linkTo }) => {
                    return (
                      <div key={id}>
                        <Link
                          {...linkTo}
                          Component={(props) => (
                            <NavLink
                              activeClassName={activeNavPanelLinkClass}
                              {...props}
                            />
                          )}
                        />
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
        {data && <SocialLinks items={data.footer.socialLinks} />}
        <div className="nav-panel-trade-mark">
          ©{new Date().getFullYear()} Vera Institute of Justice, Inc.
        </div>
      </div>
    </a.div>
  );
};

export default NavPanel;
