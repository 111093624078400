import React, { useState } from "react"
import "./NewsletterSignUp.scss"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import useNewsletterSignUp from "./use-newsletter-sign-up"

const status = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  ERROR: "error",
  SUCCESS: "success"
}

const NewsletterSignUp = () => {
  const { data } = useNewsletterSignUp()
  const [currentStatus, setCurrentStatus] = useState(status.INACTIVE)
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState()

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const mailchimpSubscribeUrl = "//vera.us8.list-manage.com/subscribe/post?u=6542df2be696ba0ea2f17b66a&id=00142e1b9c"

  return (data ?
    <div className={"newsletter-signup"}>
      <h3>NEWSLETTER SIGN UP</h3>
      <MailchimpSubscribe url={mailchimpSubscribeUrl} render={({ subscribe, status: mcStatus, message: mcMessage }) => (
        <div className={"newsletter-signup-controls " + (mcStatus ? mcStatus : currentStatus)}>
          <input
            value={email}
            placeholder="Email Address"
            onChange={(e) => {
              setEmail(e.target.value)

              if (!emailPattern.test(e.target.value)) {
                setCurrentStatus(status.ERROR)
                setMessage("Invalid email address")
                return
              }

              if (e.target.value) {
                setCurrentStatus(status.ACTIVE)
                setMessage("Email Address")
              }
            }}
            onFocus={() => {
              setCurrentStatus(status.ACTIVE)

              if (!emailPattern.test(email)) {
                setCurrentStatus(status.ERROR)
                setMessage("Invalid email address")
                return
              }

              if (email) {
                setMessage("Email Address")
              }
            }}
            onBlur={() => {
              if (!emailPattern.test(email)) {
                return
              }

              setCurrentStatus(status.INACTIVE)
              setMessage(undefined)
            }} />
          <button onClick={() => email && subscribe({ "EMAIL": email })}>Submit</button>
          {(mcMessage || message) && (status !== "active" && email) && <span dangerouslySetInnerHTML={{__html: mcMessage || message}}></span>}
        </div>)}
      />
    </div> : null
  )
}

export default NewsletterSignUp
