import React from 'react'
import { a, useSpring } from 'react-spring'
import './loading.scss'

const Loading = () => {
  const props = useSpring({
    from: { opacity: 0.25 },
    to: [{ opacity: 1 }, { opacity: 0 }]
  })
  return (
    <a.div className='Loading' style={props}></a.div>
  )
}

export default Loading;
