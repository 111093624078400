export const idtype = `
  id
  typeHandle
`;

export const link = `
  url
  text
  type
  element {
    id
    uri
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;

export const buttonLink = `
buttonLink {
  ${link}
}
`;

export const imageAsset = `
  ... on images_Asset {
    optimizedImage {
      placeholderBox
      srcset
    }
  }
`;

export const image = `
  image {
    ${imageAsset}
  }
`;

export const iconHeadingTextLink = `
  ...on pageBuilder_iconHeadingTextLink_BlockType {
    ${idtype}
    image {
      url
    }
    image2 {
      url
    }
    heading
    plainText
    ${linkTo}
  }
`;
