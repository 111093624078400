import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"

const query = gql`
  query {
    banner: globalSet (handle: "banner") {
      ...on banner_GlobalSet {
        richText
      }
    }
  }
`

const useBanner = () => {
  return useQuery(query)
}

export default useBanner