import React from "react"
import { Link } from "react-router-dom"
import Image from "components/image"

const ImageLink = ({ image, imageAltText, linkTo }) => {
  const entryURI = linkTo && linkTo.element && linkTo.element.uri;
  return (
    <div className="image-link">
      {image.length ?
      <Image alt={imageAltText} {...image[0].optimized85} /> : null}
      <Link to={"/" + entryURI}>
        <div>{linkTo.text}</div>
      </Link>
    </div>
  );
};

export default ImageLink;
