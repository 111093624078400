import React, { useState, useRef, useLayoutEffect, useCallback } from "react"
import { a, useSpring } from "react-spring"
import "./page.scss"
import styled from "styled-components"
import Blocks from "blocks"
import { ThemeContext, themes } from "blocks/theme"
import usePage from "./usePage"
import Loading from "components/loading"
import Error from "components/error"
import Footer from "components/footer"
import Banner from "components/banner"
import Nav from "components/nav"
import NavPanel from "components/nav-panel"
import NotFound from "components/notfound"
import PagePanel from "components/page-panel"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

export const PageBuilder = ({ entry }) => (
  <div>
    {entry ? <Blocks blocks={entry.pageBuilder} /> : null}
  </div>
);

const entryTypes = {
  page: PageBuilder
};

const Styled = styled(a.div)`
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.foreground};

  &::before {
    border-right: solid 2px ${({theme}) => theme.foreground};
  }

  &::after {
    border-left: solid 2px ${({theme}) => theme.foreground};
  }

  sub, sup {
    color: ${({theme}) => theme.accent};
  }

  span.definition {
    &::after {
      background-color: ${({theme}) => theme.foreground};
      color: ${({theme}) => theme.background};
    }

    span {
      background-color: ${({theme}) => theme.background};
      border-color: ${({theme}) => theme.foreground};
    }
  }

  .hamburger-menu {
    div {
      background-color: ${({theme}) => theme.foreground};
    }
  }

  @media screen and (min-width: 1000px) {
    &::before {
      border-left: solid 2px ${({theme}) => theme.foreground};
    }
  }
`

const Page = ({ match }) => {
  const onHomePage = [undefined, "home"].includes(match.params.slug)
  const screenAbove700pxWide = window.matchMedia("screen and (min-width: 700px)")
  const { loading, error, data } = usePage(match.params.slug)
  const [navHeaderHidden, setNavHeaderHidden] = useState(onHomePage)
  const [navPanelHidden, setNavPanelHidden] = useState(true)
  const [pagePanelHidden, setPagePanelHidden] = useState(true)
  const [pagePanelSelectedEntryIndex, setPagePanelSelectedEntryIndex] = useState(0)
  const lastSlugRef = useRef(match.params.slug)
  const springProps = useSpring({
    from: { opacity: 0.2 },
    to: { opacity: 1 },
    reset: lastSlugRef.current !== match.params.slug
  })

  if (lastSlugRef.current !== match.params.slug) {
    lastSlugRef.current = match.params.slug
  }

  useScrollPosition(
    ({ currPos }) => {
      const pastInnerHeight = Math.abs(currPos.y) > window.innerHeight;

      setNavHeaderHidden(!pastInnerHeight)
    },
    [match],
    undefined,
    false,
    500
  )

  const entry = data ? data.entry : undefined

  const selectPagePanelEntry = useCallback((entryIdOrSlug, url) => {
    if (entry === undefined) {
      return
    }

    const selectedEntryIndex = entry.children.findIndex(e => [e.id, e.slug].includes(entryIdOrSlug))

    if (selectedEntryIndex > -1) {
      setPagePanelSelectedEntryIndex(selectedEntryIndex)
      setPagePanelHidden(false)
      return
    }

    window.open(url)
  }, [entry])

  useLayoutEffect(() => {
    if (match.params.nestedSlug) {
      selectPagePanelEntry(match.params.nestedSlug)
    } else {
      setPagePanelHidden(true)
    }
  }, [match.params.nestedSlug, selectPagePanelEntry])


  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <Error />
  }

  entry.pageBuilder = entry.pageBuilder.map(block =>
    ({
      ...block,
      setNavPanelHidden
    }))

  if (!entry) {
    return <NotFound />
  }

  const Comp = entryTypes[entry.typeHandle]
  const theme = themes[entry.theme]

  return (
    <Styled className="page" theme={theme} style={springProps}>
      <ThemeContext.Provider value={theme}>
        {onHomePage && <Banner />}
        <Nav
          hidden={screenAbove700pxWide.matches && onHomePage && navHeaderHidden}
          route={match.params.slug}
          setNavPanelHidden={setNavPanelHidden} />
        {/* this is a dummy element to push down all content past the header
          * since it is position: fixed, therefore out of the flow
          */}
        {onHomePage ? null : <div className="nav-spacer" />}
        <Comp entry={entry} />
        <Footer />
        <PagePanel
          entries={entry.children}
          selectedEntryIndex={pagePanelSelectedEntryIndex}
          hidden={pagePanelHidden}
          setHidden={setPagePanelHidden} />
        <NavPanel
          hidden={navPanelHidden}
          route={match.params.slug}
          setHidden={setNavPanelHidden} />
      </ThemeContext.Provider>
    </Styled>
  );
};

export default Page
