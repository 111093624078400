import React from "react";
import Blocks from "blocks";
import colors from "colors";

export const themes = {
  grey: {
    accent: colors.green,
    accentInverse: colors.white,
    background: colors.lightSlateGrey,
    backgroundInverse: colors.gold,
    foreground: colors.darkGreen,
    foregroundInverse: colors.darkGreen
  },
  green: {
    accent: colors.gold,
    accentInverse: colors.lightSlateGrey,
    background: colors.green,
    backgroundInverse: colors.gold,
    foreground: colors.white,
    foregroundInverse: colors.darkGreen
  },
  darkGreen: {
    accent: colors.gold,
    accentInverse: colors.lightSlateGrey,
    background: colors.darkGreen,
    backgroundInverse: colors.gold,
    foreground: colors.lightSlateGrey,
    foregroundInverse: colors.darkGreen
  },
  white: {
    accent: colors.green,
    accentInverse: colors.lightSlateGrey,
    background: colors.white,
    backgroundInverse: colors.gold,
    foreground: colors.darkGreen,
    foregroundInverse: colors.darkGreen
  },
  gold: {
    accent: colors.lightSlateGrey,
    accentInverse: colors.green,
    background: colors.gold,
    backgroundInverse: colors.darkGreen,
    foreground: colors.darkGreen,
    foregroundInverse: colors.white
  }
};

export const ThemeContext = React.createContext({
  accent: null,
  background: null,
  backgroundInverse: null,
  foreground: null,
  foregroundInverse: null
});

const Theme = ({ children, theme }) => {
  return (
    <ThemeContext.Provider value={themes[theme]}>
      <Blocks blocks={children} />
    </ThemeContext.Provider>
  );
};

export default Theme;
