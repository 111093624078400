import { idtype } from 'querypieces';

export default `
  ...on pageBuilder_pullQuote_BlockType {
    ${idtype}
    statement
    attribution
    image {
      ... on images_Asset {
        optimizedSquare {
          placeholderBox
          srcset
        }
      }
    }
    imageAltText
    imageCaption
    captionPlacement
  }
`