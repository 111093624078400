import React from "react";
import "./resources.scss";
import styled from "styled-components"
import Blocks from "blocks";
import useTheme from "blocks/theme/useTheme"

const Styled = styled.div`
  background-color: ${({theme}) => theme.background};
  color: ${({theme}) => theme.foreground};

  .resources {
    .resource {
      border-color: ${({theme}) => theme.foreground};
    }
  }

  sub, sup {
    color: ${({theme}) => theme.accent};
  }

  span.definition {
    &::after {
      background-color: ${({theme}) => theme.foreground};
      color: ${({theme}) => theme.background};
    }

    span {
      background-color: ${({theme}) => theme.background};
      border-color: ${({theme}) => theme.foreground};
    }
  }

  @media screen and (min-width: 1000px) {
    .resources {
      .resource:hover {
        background-color: ${({theme}) => theme.foreground};
        color: ${({theme}) => theme.background};

        a {
          span {
            color: ${({theme}) => theme.backgroundInverse}
          }
        }
      }
    }
  }
`

const Resources = ({ heading, children }) => {
  const theme = useTheme()

  return (
    <Styled className="resources-wrapper" theme={theme}>
      {heading && <h2>{heading}</h2>}
      <div className="resources">
        <Blocks blocks={children} />
      </div>
    </Styled>
  )
}

export default Resources;
