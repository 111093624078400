import React from "react"
import "./pull-quote.scss"
import Image from "components/image"

const PullQuote = ({ attribution, image, imageAltText, imageCaption, captionPlacement, statement }) => {
  return (
    <div className="pull-quote">
      <div className="block pull-quote-block">
        <div className="pull-quote-statement">{statement}</div>
        <div className="pull-quote-attribution">{attribution}</div>
      </div>
      {image.length ?
      <Image
        alt={imageAltText}
        caption={imageCaption}
        captionPlacement={captionPlacement}
        {...image[0].optimizedSquare} /> : null}
    </div>
  )
}

export default PullQuote