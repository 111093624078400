import React from "react"
import "./heading-text.scss"

const HeadingText = ({ heading, richText }) => {
  return (
    <div className="block heading-text">
      <h2>{heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: richText }}></div>
    </div>
  )
}

export default HeadingText