import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Styled = styled.div`
  .image {
    background-image: url(${props => props.image.length ? props.image[0].url : ''});
  }

  @media screen and (min-width: 700px) {
    &:hover {
      .image {
        background-image: url(${props => props.image2.length ? props.image2[0].url : ''});
      }
    }
  }
`

const IconHeadingTextLink = ({ heading, image, image2, plainText, linkTo }) => {
  const entryURI = linkTo && linkTo.element && linkTo.element.uri;
  return (
    <Styled className="icon-heading-text-link" image={image} image2={image2}>
      <Link to={"/" + entryURI}>
        {image.length ?
        <div className="image" /> :
        null}
        <h2>{heading}</h2>
        <div>{plainText}</div>
      </Link>
    </Styled>
  )
}

export default IconHeadingTextLink