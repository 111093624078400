import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"

const query = gql`
  query {
    categories {
      title
      groupHandle
    }
  }
`

const useCategories = () => {
  return useQuery(query)
}

export default useCategories