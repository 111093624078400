import React from "react";
import Blocks from "blocks";
import "./image-links.scss";

const ImageLinks = ({ children, setNavPanelHidden }) => {
  const childrenWithHandlers = children.map(c => ({
    ...c,
    setNavPanelHidden
  }))
  return (
    <div className="image-links">
      <Blocks blocks={childrenWithHandlers} />
    </div>
  );
};

export default ImageLinks;

