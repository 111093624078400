import React from "react"
import "./file-download.scss"
import styled from "styled-components"
import useTheme from "blocks/theme/useTheme"

const Styled = styled.div`
  a.button-download {
    color: ${({theme}) => theme.accent};
    border-color: ${({theme}) => theme.accent};

    &:hover {
      color: ${({theme}) => theme.background};
      background-color: ${({theme}) => theme.accent};
    }
  }
`
const FileDownload = ({heading, plainText, downloadableFile}) => {
  const theme = useTheme()
  return (
    downloadableFile && downloadableFile.length && downloadableFile[0].url ?
    <Styled className="block file-download" theme={theme}>
      {heading && <h2>{heading}</h2>}
      {plainText && <div>{plainText}</div>} 
      <a
        className="button-download"
        href={downloadableFile[0].url}
        download
        target="_blank"
        rel="noopener noreferrer">
        Download
      </a>
    </Styled> : null
  )
}

export default FileDownload