import React from "react";

const Resource = ({ richTextHeading, heading, richText, plainText, linkTo }) => {
  return (
    <div className="resource">
      <a href={linkTo.url}>
        {richTextHeading && <h2 dangerouslySetInnerHTML={{ __html: richTextHeading }}></h2>}
        {!richTextHeading && heading && <h2>{heading}</h2>}
        {richText ? <div dangerouslySetInnerHTML={{ __html: richText }}></div> : <div>{plainText}</div>}
        {linkTo.text !== " " && <span>{linkTo.text}</span>}
      </a>
    </div>
  );
};

export default Resource;
