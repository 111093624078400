import React, { useRef, useState } from "react"
import Player from '@vimeo/player'
import "./video-header-detail.scss"

const VideoHeaderDetail = ({
  heading,
  plainText,
  vimeoLink,
  setNavPanelHidden
}) => {
  const iframeRef = useRef(null)
  const [overlayClicked, setOverlayClicked] = useState(false)
  const vimeoPlayerLink = vimeoLink.replace(/vimeo\.com/, 'player.vimeo.com/video')

  function play() {
    if (!iframeRef.current) {
      return;
    }
    setOverlayClicked(true)
    const player = new Player(iframeRef.current)
    player.play()
  }

  return <div className="Home-video-header">
    <div className="Home-video-header-row">
      <div className="Home-word-mark">{heading}</div>
      <div className="Home-video-header-text">
        {plainText}
      </div>
      <div className="hamburger-menu" onClick={() => setNavPanelHidden(false)}>
        <div></div>
        <div></div>
      </div>
    </div>
    <div className="vimeo-embed-wrapper">
      <iframe
        ref={iframeRef}
        title="Motion for Justice"
        src={`${vimeoPlayerLink}?title=0&byline=0&portrait=0`}
        frameBorder="0" allow="autoplay; fullscreen"
        allowFullScreen>
      </iframe>
      {!overlayClicked && <div className="vimeo-embed-overlay" onClick={play}>
        <div className="vimeo-play-button">
          <span>PLAY VIDEO</span>
        </div>
      </div>}
    </div>
  </div>;
}

export default VideoHeaderDetail;